<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Banner
                                <small class="form-text text-muted">
                                    Menu Ini Digunakan Untuk Mengelola Bunner Website
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <router-link :to="{name:'banner.tambah'}" class="btn btn-primary ml-2">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Gambar Bunner
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" placeholder="Cari banner...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-wrap card-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <!-- Checkbox -->
                                                <div class="custom-control custom-checkbox table-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="ordersSelect" id="ordersSelectAll">
                                                    <label class="custom-control-label" for="ordersSelectAll">&nbsp;</label>
                                                </div>

                                            </th>
                                            <th>
                                                Nama Banner
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="list" v-if="!fetching">
                                        <tr v-for="(banner, index) in dataBanner" :key="index">
                                            <td>
                                                <div class="custom-control custom-checkbox table-checkbox">
                                                    <input type="checkbox" class="custom-control-input" name="ordersSelect" id="orders1">
                                                    <label class="custom-control-label" for="orders1">&nbsp;</label>
                                                </div>
                                            </td>
                                            <td>
                                                <h4 class="mt-3 judul-berita">{{ banner.title }}</h4>
                                                <div>
                                                    <div class="mb-3">
                                                        <div class="file-uploaded-area" :id="'file-uploaded-area'" v-bind:style="{ 'background-image': 'url(' + banner.cover + ')' }"></div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="d-block mb-2"><small>created : {{ banner.created_at }} | status : {{ banner.status }}</small>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <router-link :to="{ name: 'banner.edit', params: { id: banner.id } }" class="btn btn-white mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Lihat berita"><span class="fe fe-edit"></span></router-link>
                                                    <button @click.prevent="postDelete(banner.id)" class="btn btn-white" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hapus berita"><span class="fe fe-trash-2"></span></button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataBanner: [],
                fetching: true,
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "admin/banner")
                    .then((response) => {
                        this.dataBanner = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.$apiconfig + 'admin/banner/delete',
                            method: 'post',
                            data: fd
                        }).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Data deleted successfully.',
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>